<template>
  <section class="policy-list">
    <div class="margin-bottom">
      <div class="flex flex-justify header-title-back">
        <h2>Referrers</h2>
        <div>
          <v-button
            class="button-theme"
            @click="handlerCreate">
            <font-awesome-icon icon="user-plus"></font-awesome-icon>
            <span>
              Create Referrer
            </span>
          </v-button>
        </div>
      </div>
      <div class="flex title-tip">
        <div class="flex">
          <span>Total Referrer </span>
          <span class="number"> {{referrer_num}}</span>
        </div>
        <div class="flex">
          <span>Total Referrer Fee   </span>
          <span class="number">{{referrer_commission_vl|formatMoney(2,true)}}</span>
        </div>
      </div>
    </div>
    <div class="show-table-maxscreen">
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-row-class-name="'table-header'"
        @row-click="rowClick">
        <el-table-column
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Join Date</span>
              <v-date-picker
                v-model="date"
                placeholder="Date"
                type="daterange"
                @change="handlerChange"
              ></v-date-picker>
            </div>
          </template>
          <template slot-scope="scope">
            <span >{{showMonth(scope.row.join_date)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Name of Referrer</span>
              <v-input
                v-model="name"
                placeholder="Search"
                @change="handlerChange"></v-input>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="contact_phone"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Phone Number</span>
              <v-input
                v-model="contact_phone"
                @change="handlerChange">
              </v-input>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="policy_number"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Number of Policy</span>
              <v-min-max
                v-model="minMax"
                @change="handlerChange"></v-min-max>
            </div>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <el-row>
                <v-row-col>
                  <span class="is-bold">Join Date</span>
                  <v-date-picker
                    v-model="date"
                    placeholder="Date"
                    type="daterange"
                    @change="handlerChange"
                  ></v-date-picker>
                </v-row-col>

                <v-row-col>
                  <span class="is-bold">Name of Referrer</span>
                  <v-input
                    v-model="name"
                    placeholder="Search"
                    @change="handlerChange"></v-input>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Phone Number</span>
                  <v-input
                    v-model="contact_phone"
                    placeholder="Search"
                    @change="handlerChange"></v-input>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Number of Policy</span>
                  <v-min-max
                    v-model="minMax"
                    @change="handlerChange"></v-min-max>
                </v-row-col>
              </el-row>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table"
        @click="rowClick(item)">


        <div
          class="tr">
          <span>Join Date</span>
          <span>{{showMonth(item.join_date)}}</span>
        </div>
        <div
          class="tr">
          <span>Name of Referrer</span>
          <span>{{item.name}}</span>
        </div>

        <div
          class="tr">
          <span>Phone Number</span>
          <span>{{item.contact_phone}}</span>
        </div>

        <div
          class="tr">
          <span>Number of Policy</span>
          <span>{{item.policy_number}}</span>
        </div>

      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange" />
    <create-referrer-dialog
      ref="createDialog"
      @refresh="handlerRefresh"></create-referrer-dialog>
  </section>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOptions from '@/mixins/options'
import createReferrerDialog from '@/views/components/dialog/createReferrerDialog'
import {getReferrerList,getReferrerSummary,createReferrer} from '@api/referrer'
import {mapActions, mapState} from 'vuex'
export default {
  name: 'memberReferral',
  components:{
    createReferrerDialog
  },
  mixins: [MixinOptions,MixinFormat],
  props:{
  },
  data(){

    return {
      show:false,
      date:'',
      contact_phone:'',
      name:'',
      minMax:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        to:'',
        per_page:1,
        size:20
      },
      tableData: [],
      referrer_num:0,
      referrer_commission_vl:0,
      filter:{
        date:'',
        contact_phone:'',
        name:'',
        minMax:'',
      }
    }
  },
  computed:{
    ...mapState('common',['listSearch']),
  },
  created(){
    // let {member={}} = this.listSearch||{}
    // Object.keys(this.filter).some((key)=>{
    //   if(member[key]){
    //     this[key] = member[key]
    //   }
    // })
    this.network().getReferrerSummary()
    this.network().getReferrerList()
  },
  methods:{
    ...mapActions('common',['updateGlobalOptions','setListSearch']),
    ...mapActions('statement',['setStatementTab']),

    handlerRefresh(params){
      this.network().createReferrer(params)
    },
    handlerCreate(){
      this.$refs.createDialog.showReferDialog()
    },
    rowClick({id}){
      this.setStatementTab('1')
      // Object.keys(this.filter).some((key)=>{
      //   if(this[key])
      //     this.filter[key] = this[key]
      // })
      // this.setListSearch({
      //   member:this.filter
      // })
      this.$router.push('/member/referral/statement/'+id)
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.network().getReferrerList()
    },
    handleSizeChange(size){
      this.pageConfig.size =size
      this.network().getReferrerList()
    },
    handlerChange(){
      this.pageConfig.currentPage = 1
      this.network().getReferrerList()
    },
    network() {
      return {
        getReferrerList: async () => {
          const { data } = await getReferrerList({
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
            join_date_start:this.date?this.date[0]: '',
            join_date_end:this.date?this.date[1]: '',
            name:this.name,
            contact_phone:this.contact_phone,
            policy_num_min:this.minMax?this.minMax[0]:'',
            policy_num_max:this.minMax?this.minMax[1]:''
          })
          this.tableData = data.data
          let {meta} =data
          this.pageConfig.total = meta.total
        },
        getReferrerSummary: async () => {
          const { data } = await getReferrerSummary({
          })
          this.referrer_num = data.referrer_num
          this.referrer_commission_vl = data.referrer_commission_vl
        },
        createReferrer: async (params) => {
          const { data } = await createReferrer(params)
          this.handlerChange()
          this.updateGlobalOptions()

        }
      }
    },
    toFixed(data) {
      const arr = String(data).split('.')
      let num = arr[0] // 整数部分
      let resultArr = []
      while (num.length > 0) {
        resultArr.unshift(num.slice(-3))
        num = num.slice(0, -3)
      }
      let result = resultArr.join(',')
      return result
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/policyList';
.margin-bottom{
  margin-bottom: 20px;
}
.header-title-back{
  h2{
    font-size: 36px;
  }
}
.title-tip{
  color: #4A5568;
  font-size: 16px;
  font-weight: 600;
  .number{
    font-size: 16px;
    font-weight: normal;
    margin: 0 25px 0 12px;
  }
}
.button-theme{
  font-size: 16px;
  font-weight: 600;
}

</style>
